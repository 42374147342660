<template>
  <div class="package-breakdown">
    <div class="package-breakdown-data-container">
      <div class="left-title-div">
        <div class="left-top" />
        <div class="left-titles">
          <div
            class="titles"
            :class="{
              bgColor: index & (1 == 0x1),
              isSpBottom: index == viewState.packageBreakdownList.length - 1,
            }"
            v-for="(item, index) in viewState.packageBreakdownList"
            :key="index"
          >
            <span>{{ item.title }}</span>
          </div>
          <div class="hidden-div-bottom">
            <div class="white-line" />
          </div>
          <div class="hidden-div-left" />
        </div>
      </div>
      <el-scrollbar always>
        <div class="right-data-div">
          <div class="title-container">
            <div class="data-title premium">
              <p class="topText">プレミアム</p>
              <p class="bottomText">PREMIUM</p>
            </div>
            <div class="divider" />
            <div class="data-title business">
              <p class="topText">ビジネス</p>
              <p class="bottomText">BUSINESS</p>
            </div>
            <div class="divider" />
            <div class="data-title enterprise">
              <p class="topText">エンタープライズ</p>
              <p class="bottomText">ENTERPRISE</p>
            </div>
          </div>
          <div class="data-container">
            <div
              class="data-row"
              :class="{
                bgColor: index & (1 == 0x1),
                isSpBottom: index == viewState.packageBreakdownList.length - 1,
              }"
              v-for="(item, index) in viewState.packageBreakdownList"
              :key="index"
            >
              <div class="data-premium">{{ item.premium }}</div>
              <div class="divider" />
              <div class="data-business">{{ item.business }}</div>
              <div class="divider" />
              <div class="data-enterprise">{{ item.enterprise }}</div>
            </div>
            <div class="coming-soon" :style="{
              width: viewState.comingSoonWidth + 'px',
              left: viewState.comingSoonLeft + 'px',
            }">
              <span>Coming <br class="is-sp">Soon...</span>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { ElScrollbar } from "element-plus";
import { defineComponent, reactive, onMounted } from "vue";

export default defineComponent({
  components: {
    ElScrollbar,
  },
  setup() {
    const viewState = reactive({
      packageBreakdownList: [
        {
          title: "動画視聴（同時視聴）",
          premium: "1名",
          business: "〜5名",
          enterprise: "5名〜",
        },
        {
          title: "管理者権限",
          premium: "なし",
          business: "一部制限あり",
          enterprise: "全機能利用可能",
        },
        {
          title: "管理機能",
          premium: "ー",
          business: "基本的なレポート\nID管理",
          enterprise: "高度なレポート機能\nコンテンツカスタマイズ",
        },
        {
          title: "決済方法",
          premium: "クレジットカード",
          business: "クレジットカード",
          enterprise: "請求書支払い",
        },
        {
          title: "月額利用料金",
          premium: "¥3,300/月（税込）",
          business: "〜¥16,500円/月（税込）",
          enterprise: "要お問い合わせ",
        },
      ],
      comingSoonWidth: 0,
      comingSoonLeft: 0,
    });

    const calculateComingSoonCss = () => {
      const browserWidth = window.innerWidth;
      const columnWidth = document.getElementsByClassName('data-business')[0]?.offsetWidth ?? 0; // 1カラム分の横幅
      console.log(columnWidth)
      const comingSoonWidth = (columnWidth + 2); // ビジネスの横幅 + border
      if (browserWidth > 992) {
        // pc
        viewState.comingSoonWidth =comingSoonWidth;
        viewState.comingSoonLeft = columnWidth;
      } else {
        // sp
        viewState.comingSoonWidth = comingSoonWidth;
        viewState.comingSoonLeft = columnWidth;
      }
    }

    onMounted(() => {
      calculateComingSoonCss();
      window.addEventListener('resize', calculateComingSoonCss);
    });

    return {
      viewState,
    };
  },
});
</script>

<style lang="scss" scoped>
.package-breakdown {
  max-width: 1366px;
  .package-breakdown-data-container {
    display: flex;
    flex-direction: row;
    .left-title-div {
      max-width: 399px;
      .left-top {
        margin-left: 115px;
        max-width: 284px;
        height: 120px;
        position: relative;
        &::after {
          position: absolute;
          bottom: -2px;
          left: 0px;
          content: "";
          max-width: 230px;
          width: 100%;
          height: 0px;
          border-bottom: 4px solid $black;
          z-index: 1;
        }
      }
      .left-titles {
        position: relative;
        .titles {
          padding-left: 115px;
          max-width: 399px;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          &::after {
            position: absolute;
            top: -0.5px;
            left: 115px;
            right: 0px;
            content: "";
            height: 0px;
            border-bottom: 0.5px solid $black-50;
          }
          span {
            display: block;
            max-width: 399px;
            padding-right: 54px;
            min-width: 90px;
            text-align: left;
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            color: $black;
          }
        }
        .bgColor {
          background: rgba(240, 243, 248, 0.5);
        }
        .hidden-div-bottom {
          display: none;
        }
        .hidden-div-left {
          display: none;
        }
      }
    }
    .el-scrollbar__bar.is-horizontal {
      display: none;
    }
    .el-scrollbar__bar.is-vertical {
      display: none;
    }
    .right-data-div {
      max-width: 967px;
      .premium {
        width: 284px;
      }
      .business {
        width: 284px;
      }
      .enterprise {
        width: 399px;
        padding-right: 115px;
      }
      .title-container {
        height: 120px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .divider {
          width: 0px;
          height: 20px;
          border-right: 0.5px solid $black-50;
        }
        .data-title {
          height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .topText {
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            text-align: center;
            letter-spacing: 0.09em;
            color: rgba(89, 88, 87, 0.8);
          }
          .bottomText {
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            color: $black;
          }
        }
      }
      .data-container {
        display: flex;
        flex-direction: column;
        position: relative;
        .bgColor {
          background: rgba(240, 243, 248, 0.5);
        }
        .data-row {
          position: relative;
          &::after {
            position: absolute;
            top: -0.5px;
            left: 0px;
            content: "";
            width: 852px;
            height: 0px;
            border-bottom: 0.5px solid $black-50;
          }
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 120px;
          font-weight: 500;
          font-size: 16px;
          // line-height: 45px;
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          color: $black;
          .divider {
            width: 0px;
            height: 20px;
            border-right: 0.5px solid $black-50;
          }
          .data-premium {
            width: 284px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: pre-wrap;
            text-align: center;
            line-height: 1.5em;
          }
          .data-business {
            width: 284px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: pre-wrap;
            text-align: center;
            line-height: 1.5em;
          }
          .data-enterprise {
            width: 399px;
            padding-right: 115px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: pre-wrap;
            text-align: center;
            line-height: 1.5em;
          }
        }
        .coming-soon {
          position: absolute;
          display: flex;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          span {
            color: #fff;
            font-size: 2rem;
            margin: auto;
            text-align: center;
            vertical-align: middle;
          }
        }
      }
    }
  }

  @include mq(sm) {
    .package-breakdown-data-container {
      .el-scrollbar {
        flex-grow: 1;
      }
      .el-scrollbar__bar.is-horizontal {
        display: block;
        height: 5px;
      }
      .el-scrollbar__bar.is-vertical {
        display: none;
      }
      .el-scrollbar__bar {
        border-radius: 2px;
        left: 6px;
        bottom: 5px;
        z-index: 1;
        overflow: hidden;
      }
      .el-scrollbar__thumb {
        background-clip: content-box;
        background-color: $primary;
        box-shadow: 0px 20px 30px rgba(72, 159, 157, 0.25);
        opacity: 1;
      }
      display: flex;
      flex-direction: row;
      margin-top: 0px;
      .left-title-div {
        position: relative;
        .left-top {
          margin-left: 20px;
          width: 111px;
          height: 81px;
          &::after {
            bottom: -1px;
            width: 111px;
          }
        }
        .left-titles {
          .titles {
            padding-left: 20px;
            width: 131px;
            height: 100px;
            &::after {
              display: none;
            }
            span {
              display: block;
              width: 106px;
              padding-right: unset;
              font-weight: 700;
              font-size: 16px;
              line-height: 170%;
              display: flex;
              align-items: center;
              letter-spacing: 0.1em;
              color: $black;
            }
          }
          .bgColor {
            background: rgba(240, 243, 248, 0.5);
          }
          .isSpBottom {
            height: 115px !important;
          }
          .hidden-div-bottom {
            display: block;
            position: absolute;
            left: 0px;
            bottom: 0px;
            background: $grey;
            width: 100vw;
            height: 15px;
            .white-line {
              width: 0px;
              height: 15px;
              margin-left: 131px;
              border-right: 1px solid $white;
            }
          }
          .hidden-div-left {
            display: block;
            position: absolute;
            background: linear-gradient(
              270deg,
              rgba(54, 131, 245, 0.05) -4.76%,
              rgba(54, 131, 245, 0) 100%
            );
            transform: matrix(-1, 0, 0, 1, 0, 0);
            width: 20px;
            height: 100%;
            right: -20px;
            top: 0px;
          }
        }
      }
      .right-data-div {
        .premium {
          min-width: 92px;
          width: 92px;
        }
        .business {
          min-width: 102px;
          width: 102px;
        }
        .enterprise {
          min-width: 122px;
          width: 122px;
          padding-right: 0px;
        }
        .title-container {
          height: 81px;
          min-width: 316px;
          align-items: flex-end;
          border-bottom: 0.5px solid $black-50;
          .divider {
            margin-bottom: 22px;
          }
          .data-title {
            height: 81px;
            flex-direction: column-reverse;
            justify-content: flex-start;
            min-width: 108px;
            flex-basis: 108px;
            flex-grow: 1;
            .topText {
              margin-top: 10px;
              margin-bottom: 12px;
              font-weight: 500;
              font-size: 12px;
              line-height: 100%;
              text-align: center;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.5;
            }
            .bottomText {
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 18px;
              line-height: 100%;
              text-align: center;
              letter-spacing: 0.08em;
              color: $black;
            }
          }
        }
        .data-container {
          min-width: 316px;
          display: flex;
          flex-direction: column;
          .bgColor {
            background: rgba(240, 243, 248, 0.5);
          }
          .isSpBottom {
            height: 115px !important;
          }
          .data-row {
            &::after {
              display: none;
            }
            height: 100px;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            display: flex;
            align-items: center;
            letter-spacing: 0.1em;
            color: $black;
            .data-premium {
              min-width: 108px;
              flex-basis: 108px;
              flex-grow: 1;
            }
            .data-business {
              min-width: 108px;
              flex-basis: 108px;
              flex-grow: 1;
            }
            .data-enterprise {
              min-width: 108px;
              flex-basis: 108px;
              padding-right: 0px;
              flex-grow: 1;
            }
          }
          .coming-soon {
            position: absolute;
            display: flex;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            span {
              color: #fff;
              font-size: 1rem;
              margin: auto;
              text-align: center;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
</style>
