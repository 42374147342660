<template>
  <router-link to="/apply" class="bottom-btn">
    <div class="btn">
      <div class="btn-label">{{ label }}</div>
      <div class="btn-icon">
        <icon name="bottom-btn-icon" />
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['label'],
}
</script>

<style lang="scss" scoped>
.bottom-btn {
  display: block;
  margin: 0 115px 100px;
  text-decoration: none;
  @include mq(sm) {
    margin: 0 50px 80px;
  }
  .btn {
    cursor: pointer;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    backdrop-filter: blur(10px);
    @include mq(sm) {
      height: 60px;
    }
    .btn-label {
      margin-left: 30px;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: $primary;
      @include mq(sm) {
        margin-left: 20px;
      }
    }
    svg {
      width: 40px;
      height: 10px;
      margin-right: 20px;
    }
  }
}
</style>