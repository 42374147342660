<template>
  <div class="page-top">
    <div class="nologin">
      <div class="top-bg1"></div>
      <div class="top-bg2"></div>
      <div class="top-bg3"></div>
      <div class="top-bg4"></div>
      <div class="top-bg5">
        <icon name="top-bg5" class="top-bg5-icon"></icon>
        <icon name="sp-top-bg5" class="sp-top-bg5-icon"></icon>
      </div>
      <div class="read">
        <h1 class="title"><icon name="logo" class="logo"></icon> とは</h1>
        <p class="read-text">
          単なる知識取得で終わらず、学んだその日から業務の場で｢実践｣できるための<br />DX・デジタルマーケティング専門eラーニングサービスです。
        </p>
      </div>
      <div class="nologin-center-img">
        <img
          src="@/assets/img/about.png"
          alt=""
          class="nologin-center-img-pc"
        />
        <img
          src="@/assets/img/about-sp.png"
          alt=""
          class="nologin-center-img-sp"
        />
      </div>
    </div>
    <section class="category">
      <icon name="bg-dot-circle" class="bg-dot-circle"></icon>
      <div class="panel-body">
        <CommonBlockTitle
          title="専門カテゴリーに分類された講座を毎月公開"
        ></CommonBlockTitle>
        <ul class="category-ul">
          <li class="category-li">
            <icon name="category1" class="category-icon"></icon>
            <p class="category-name">デジタルトランス<br />フォーメーション</p>
          </li>
          <li class="category-li">
            <icon name="category2" class="category-icon"></icon>
            <p class="category-name">SNS</p>
          </li>
          <li class="category-li">
            <icon name="category3" class="category-icon"></icon>
            <p class="category-name">デジタル広告</p>
          </li>
          <li class="category-li">
            <icon name="category4" class="category-icon"></icon>
            <p class="category-name">デザイン＆クリエイティブ</p>
          </li>
          <li class="category-li">
            <icon name="category5" class="category-icon"></icon>
            <p class="category-name">WEBサイト＆EC運営</p>
          </li>
          <li class="category-li">
            <icon name="category6" class="category-icon"></icon>
            <p class="category-name">データ&アナリティクス</p>
          </li>
          <li class="category-li">
            <icon name="category7" class="category-icon"></icon>
            <p class="category-name">業務改善</p>
          </li>
          <li class="category-li">
            <icon name="category8" class="category-icon"></icon>
            <p class="category-name">店舗集客＆IT活用</p>
          </li>
        </ul>
      </div>
    </section>
    <section class="user-num">
      <div class="panel-body">
        <div class="row">
          <p class="sum-text">ジッセン!サービスのご利用者数</p>
          <p class="sum">累計 <span class="num">1,000社</span> 以上</p>
        </div>
        <div class="row">
          <p class="sum-text">E-LEARING受講ユーザ数</p>
          <p class="sum"><span class="num">20,000人</span> 以上</p>
        </div>
        <div class="kome">※2018年8月現在</div>
      </div>
    </section>
    <section class="reason">
      <popularLearning></popularLearning>
    </section>
    <i class="about-purple-circle"></i>
    <section class="recommend">
      <div class="title">
        <CommonBlockTitle title="こんな方へおすすめ"></CommonBlockTitle>
      </div>
      <dl class="panel-body">
        <div class="recommend-item">
          <dt class="dt">デジタルトランスフォーメーションの入門</dt>
          <dd class="dd">
            DXを１から学びたい方<br />知識としてはしっているが実践する方法が知りたい方
          </dd>
        </div>
        <div class="hr"></div>
        <div class="recommend-item">
          <dt class="dt">スキル向上</dt>
          <dd class="dd">
            ご自身のDX関連スキルを実業務に反映させるスキルや応用力を身に着けたいかた。
          </dd>
        </div>
        <div class="hr"></div>
        <div class="recommend-item">
          <dt class="dt">人材育成</dt>
          <dd class="dd">
            社内のDX推進担当者やマーケティング担当者など人材育成を行いたい方。<br />新入社員研修での活用もできます。
          </dd>
        </div>
      </dl>
    </section>
    <section class="testimonials">
      <div class="title">
        <CommonBlockTitle
          title="TESTIMONIALS"
          sub-title="推進コメント"
        ></CommonBlockTitle>
      </div>
      <div class="testimonials-list">
        <div
          class="testimonial"
          v-for="(testimonial, index) in state.testimonials"
          :key="index"
        >
          <img class="img" :src="testimonial.img" alt="" />
          <div class="info">
            <div class="company">{{ testimonial.company }}</div>
            <div class="post" v-if="testimonial.post">{{
              testimonial.post
            }}</div
            ><div class="name">{{ testimonial.name }}</div>
            <div class="text">
              <div
                :class="
                  store._state.data.isSp && testimonial.open ? 'open' : 'close'
                "
              >
                {{ testimonial.text }}
              </div>
              <p
                @click="readomore(index)"
                v-if="store._state.data.isSp"
                class="readmore-button"
              >
                <template v-if="!testimonial.open">
                  read more<i class="read-icon read-icon--more"
                    ><img src="@/assets/img/mypage-read-action.png" alt=""
                  /></i>
                </template>
                <template v-else>
                  read less
                  <i class="read-icon read-icon--less"
                    ><img src="@/assets/img/mypage-read-action.png" alt=""
                  /></i>
                </template>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <i class="about-green-circle"></i>
    <!-- <section class="users-voice">
      <div class="panel-body">
        <CommonBlockTitle title="ユーザの声"></CommonBlockTitle>
        <UsersVoices></UsersVoices>
      </div>
    </section> -->
    <section class="registration">
      <div class="title">
        <CommonBlockTitle title="新規会員登録"></CommonBlockTitle>
        <p class="sub-title">
          まずは、無料サブスクリプションで体型的なDXを学んでスキルアップを目指しましょう。
        </p>
      </div>
      <bottomBtn :label="'新規登録'" />
      <div class="plan-title">
        <div class="plan-title-en">PLAN DETAILS</div>
        <div class="plan-title-ja">機能内訳</div>
      </div>
      <breakdown class="breakdown" />
      <bottomBtn :label="'新規登録'" />
    </section>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import popularLearning from "./components/PopularLearning.vue";
import bottomBtn from "./components/BottomBtn.vue";
import CommonBlockTitle from "../../components/CommonTitle/CommonBlockTitle.vue";
import breakdown from "@/components/package-breakdown.vue";
// import UsersVoices from "./components/UsersVoices.vue";
export default defineComponent({
  components: {
    popularLearning,
    bottomBtn,
    CommonBlockTitle,
    breakdown,
    // UsersVoices,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isEnterprise = computed(() => store.state.user.isEnterprise)

    const state = reactive({
      testimonials: [
        {
          img: require("../../assets/img/teacher-kawazoe.jpg"),
          company: "エバン合同会社",
          post: "CEO",
          name: "川添 隆氏",
          text: "「企業がデジタルシフトをするか？」以前に、「生活者はすでにデジタルシフトをしています。」ということは、あらゆる企業がデジタルでの活動が必要になってくる時代。そこで必要なのは“知識”よりも“実践”です。私が“実践”してきたことを伝えていきます。",
          open: false,
        },
        {
          img: require("../../assets/img/teacher-takeya.jpg"),
          company: "ムーブメント",
          post: "代表",
          name: "竹谷 力氏",
          text: `ジッセン!DXでは多くの専門家がその得意領域を解説しています。私が昔ベンチャー企業でネット広告の運用をしていた時、周りには詳しい人がおらず、とても苦戦した記憶があります。
          私の講座では、当時自分自身が知りたかった詳細や、媒体からは伝えきれない部分など、できる限り盛り込み元気に講義していますので是非ご覧ください。`,
          open: false,
        },
        {
          img: require("../../assets/img/teacher-arizono.jpg"),
          company: "zonari合同会社",
          post: "代表執行役社長",
          name: "有園 雄一氏",
          text: `アトリビューション講座は、広告効果測定の基礎を知りたい方、アトリビューションとは何か？について基礎から押さえておきたい方に活用して欲しい講座です。
          また、マーケティングコンサルタント講座は、マーケティングコンサルタントとして仕事をしたいと考えている方向けの講座です。
          どんな知識を身につけ、どのような心構えや態度で仕事をすればいいのか。コンサルタントとは、知識と論理的思考回路だけではなく、人間力も試される仕事だと考えており、そのような点を感じ取ってもらえればと思っています。`,
          open: false,
        },
      ],
    });
    window.scrollTo(0, 0);
    const readomore = (num) => {
      state.testimonials[num].open = !state.testimonials[num].open;
    };
    onMounted(async () => {
      if (isEnterprise.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        if (isEnterprise.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      store,
      state,
      userStore: store.state.user,
      readomore,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-top {
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  margin: 0 auto;
  .nologin {
    max-width: 1366px;
    width: 100%;
    position: relative;
    margin-bottom: 100px;
    .top-bg1 {
      width: 70px;
      height: 70px;
      background: $secondary-40;
      border-radius: 50%;
      position: absolute;
      z-index: -1;
      right: 112px;
      top: 120px;
      @include mq(sm) {
        width: 40px;
        height: 40px;
        top: auto;
        bottom: 250px;
        right: -5px;
      }
    }
    .top-bg2 {
      background: $primary-80;
      border-radius: 50%;
      width: 213px;
      height: 213px;
      position: absolute;
      z-index: -1;
      top: 200px;
      right: 175px;
      @include mq(sm) {
        width: 100px;
        height: 100px;
        right: 20px;
        top: auto;
        bottom: 150px;
      }
    }
    .top-bg3 {
      width: 584px;
      height: 584px;
      background: $grey;
      border-radius: 50%;
      position: absolute;
      z-index: -1;
      left: -292px;
      top: 100px;
      @include mq(sm) {
        width: 255px;
        height: 255px;
        left: -127px;
        top: 127px;
      }
    }
    .top-bg4 {
      width: 1316px;
      height: 772px;
      background-image: url("../../assets/img/top-bg4.png");
      position: absolute;
      top: 447px;
      left: 0;
      z-index: -1;
      @include mq(sm) {
        background-image: url("../../assets/img/sp-top-bg4.png");
        background-size: cover;
        background-position: center;
        top: 298px;
        left: 0;
        width: calc(100% - 20px);
        height: 272px;
      }
    }
    .top-bg5 {
      position: absolute;
      z-index: -1;
      top: 755px;
      right: 50px;
      @include mq(sm) {
        top: 390px;
        right: 0;
      }
      .top-bg5-icon {
        width: 1267px;
        height: 202px;
        @include mq(sm) {
          display: none;
        }
      }
      .sp-top-bg5-icon {
        width: 355px;
        height: 131px;
        display: none;
        @include mq(sm) {
          display: block;
        }
      }
    }
    .read {
      margin: 100px 0 76px 115px;
      @include mq(sm) {
        margin-left: 50px;
        margin-top: 80px;
      }
      .title {
        margin-bottom: 40px;
        .logo {
          width: 144px;
          height: 30px;
          vertical-align: bottom;
        }
      }
      .read-text {
        line-height: 1.87;
        letter-spacing: 0.1em;
        @include mq(sm) {
          margin-right: 50px;
          font-size: 14px;
          br {
            display: none;
          }
        }
      }
    }
    .nologin-center-img {
      margin-left: 50px;
      max-width: 1266px;
      height: 500px;
      z-index: 5;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      @include mq(sm) {
        margin-left: 20px;
        margin-top: 40px;
        width: 335px;
        height: 165px;
      }
      .nologin-center-img-pc {
        width: 100%;
        @include mq(sm) {
          display: none;
        }
      }
      .nologin-center-img-sp {
        display: none;
        @include mq(sm) {
          display: block;
          width: 100%;
        }
      }
    }
  }
  .category {
    position: relative;
    margin-bottom: 65px;
    margin-left: 50px;
    @include mq(sm) {
      margin-bottom: 60px;
      margin-left: 20px;
    }
    .bg-dot-circle {
      position: absolute;
      width: 620px;
      height: 620px;
      left: -310px;
      bottom: -310px;
      z-index: -1;
      @include mq(sm) {
        width: 282px;
        height: 282px;
        left: -140px;
        bottom: -140px;
      }
    }
    .panel-body {
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
      backdrop-filter: blur(10px);
      position: relative;
      padding: 65px;
      @include mq(sm) {
        padding: 30px 50px 30px 30px;
      }

      .category-ul {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin: 65px -20px -20px 0;
        @include mq(sm) {
          margin: 40px -20px -20px 0;
          font-size: 12px;
        }
        .category-li {
          width: calc(25% - 20px);
          height: 268px;
          padding-top: 76px;
          margin: 0 20px 20px 0;
          background-color: $primary-2;
          @include mq(sm) {
            width: calc(50% - 20px);
            height: 126px;
            padding-top: 30px;
          }
          .category-icon {
            width: 92px;
            height: 92px;
            @include mq(sm) {
              width: 36px;
              height: 36px;
            }
          }
          // .category-name {
          // }
        }
      }
    }
    .top-bottom-top-bg {
      position: absolute;
      width: 584px;
      height: 584px;
      background: $primary;
      opacity: 0.8;
      border-radius: 50%;
      right: -289px;
      top: -277px;
      z-index: -1;
      @include mq(sm) {
        width: 255px;
        height: 255px;
        right: -127px;
        top: -127px;
      }
    }
    .top-bottom-bottom-bg {
      position: absolute;
      width: 584px;
      height: 584px;
      background: $secondary;
      opacity: 0.4;
      border-radius: 50%;
      left: -242px;
      bottom: -242px;
      z-index: -1;
      @include mq(sm) {
        width: 255px;
        height: 255px;
        left: -108px;
        bottom: -177px;
      }
    }
  }
  .user-num {
    margin: 0 50px 65px;
    font-family: "Roboto Condensed", "Noto Sans JP", sans-serif;
    letter-spacing: 0.05em;
    @include mq(sm) {
      margin: 0 20px 60px;
    }
    .panel-body {
      background: rgba(255, 255, 255, 0.95);
      backdrop-filter: blur(10px);
      position: relative;

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 80px 0;
        border-bottom: 0.5px solid #0f0f11;
        margin: 0 65px;
        line-height: 2;
        @include mq(sm) {
          display: block;
          padding: 30px 0;
          margin: 0 30px;
        }
        .sum-text {
          font-size: 26px;
          font-weight: 700;
          @include mq(sm) {
            font-size: 20px;
          }
        }
        .sum {
          font-size: 18px;
          color: $dark-grey;
          @include mq(sm) {
            font-size: 16px;
          }
          .num {
            font-weight: 700;
            font-size: 40px;
            color: $secondary;
            @include mq(sm) {
              font-size: 30px;
            }
          }
        }
      }
      .kome {
        background-color: $black;
        letter-spacing: 0.08em;
        color: $white;
        text-align: right;
        font-size: 14px;
        padding: 22px 65px;
        margin-top: -1px;
        @include mq(sm) {
          font-size: 12px;
          padding: 22px 30px;
        }
      }
    }
  }
  .reason {
    margin: 0 50px 65px 0;
    @include mq(sm) {
      margin: 0 20px 60px 0;
    }
    &::v-deep {
      .swiper-container {
        padding-top: 65px;
        @include mq(sm) {
          padding-top: 0;
        }
      }
    }
  }
  .recommend {
    margin: 0 50px 65px;
    letter-spacing: 0.1em;
    .title {
      margin: 0 0 65px 65px;
      @include mq(sm) {
        margin: 0 0 10px 0;
      }
    }
    .panel-body {
      background: rgba(255, 255, 255, 0.95);
      backdrop-filter: blur(10px);
      display: flex;
      padding: 15px;
      position: relative;
      @include mq(sm) {
        display: block;
        padding: 30px;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
        box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
      }
      .recommend-item {
        flex-basis: 33.33%;
        padding: 40px;
        @include mq(sm) {
          padding: 30px 0;
        }
        .dt {
          font-size: 18px;
          font-weight: 700;
          line-height: 1.67;
          margin-bottom: 14px;
          @include mq(sm) {
            font-size: 16px;
            line-height: 1.87;
          }
        }
        .dd {
          color: $dark-grey;
          line-height: 2.14;
          font-size: 14px;
        }
      }
      .hr {
        display: block;
        width: 1px;
        height: 20px;
        align-self: center;
        border: none;
        background-color: $black;
        @include mq(sm) {
          width: 30px;
          height: 0.5px;
        }
      }
    }
  }
  .testimonials {
    margin: 0 50px 15px;
    .title {
      margin: 0 0 15px 65px;
      @include mq(sm) {
        margin: 0 0 10px 0;
      }
    }
    .testimonials-list {
      margin: 0 65px;
      @include mq(sm) {
        margin: 0;
      }

      .testimonial {
        display: flex;
        align-items: flex-start;
        border-top: 0.5px solid $black;
        padding: 50px 0;
        &:first-child {
          border-top: none;
        }
        @include mq(sm) {
          display: block;
          padding: 30px 0;
        }
        .img {
          width: 227px;
          margin-right: 50px;
          @include mq(sm) {
            width: 60px;
            margin-bottom: 15px;
          }
        }
        .info {
          flex: 1;
          .company {
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 700;
            @include mq(sm) {
              font-size: 14px;
            }
          }
          .post, .name {
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: 700;
            @include mq(sm) {
              font-size: 12px;
            }
          }
          .text {
            white-space: pre-line;
            line-height: 1.87;
            background-color: $grey;
            padding: 20px 30px;
            @include mq(sm) {
              font-size: 14px;
              padding: 30px;
            }
          }
        }
        @include mq(sm) {
          .close {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
        .readmore-button {
          margin-top: 10px;
          color: $secondary;
          font-size: 14px;
          font-family: Noto Sans JP;
          .read-icon {
            display: inline-flex;
            padding-left: 0.5em;
            &--less {
              img {
                transform: rotateX(180deg);
              }
            }
          }
        }
      }
    }
  }
  .users-voice {
    margin: 0 0 65px 50px;
    @include mq(sm) {
      margin: 0 0 60px 20px;
    }
    .panel-body {
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
      backdrop-filter: blur(10px);
      position: relative;
      padding: 65px 115px 65px 65px;
      @include mq(sm) {
        padding: 60px 50px 50px 30px;
      }
    }
  }
  .registration {
    .title {
      margin: 0 115px 65px;
      display: flex;
      justify-content: space-between;
      line-height: 1.87;
      letter-spacing: 0.1em;
      align-items: flex-start;
      @include mq(sm) {
        display: block;
        margin: 0 50px;
      }
      .sub-title {
        color: $dark-grey;
        width: 454px;
        @include mq(sm) {
          width: auto;
          font-size: 14px;
          margin: 30px 0;
        }
      }
    }
    .plan-title {
      margin: 0 115px 30px;
      @include mq(sm) {
        margin: 0 50px 40px;
      }

      .plan-title-en {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        letter-spacing: 0.05em;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 40px;
          height: 0px;
          left: 0;
          bottom: -22px;
          border-bottom: 4px solid $black;
        }
        @include mq(sm) {
          font-size: 30px;
          line-height: 40px;
          letter-spacing: 0.08em;
          &::before {
            bottom: -12px;
          }
        }
      }
      .plan-title-ja {
        margin-top: 40px;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $black-50;
        @include mq(sm) {
          margin-top: 32px;
          font-size: 18px;
          line-height: 100%;
          letter-spacing: 0.08em;
        }
      }
    }

    .breakdown {
      margin-bottom: 100px;
      @include mq(sm) {
        margin-bottom: 60px;
      }
    }
  }
}
.about-purple-circle {
  position: relative;
  &:before {
    content: "";
    width: 584px;
    height: 584px;
    position: absolute;
    top: -230px;
    right: -289px;
    border-radius: 50%;
    background: $primary;
    opacity: 0.8;
    z-index: -1;
    @include mq(sm) {
      width: 255px;
      height: 255px;
      right: -127px;
      top: -127px;
    }
  }
}
.about-green-circle {
  position: relative;
  &:before {
    content: "";
    width: 584px;
    height: 584px;
    position: absolute;
    top: -230px;
    left: -289px;
    border-radius: 50%;
    background: $secondary;
    opacity: 0.8;
    z-index: -1;
    @include mq(sm) {
      width: 255px;
      height: 255px;
      left: -127px;
      top: -127px;
    }
  }
}
</style>
