<template>
  <div
    class="popular-learning-item"
    ref="popularLearningItem"
  >
    <div
      class="item-top-img"
      :style="{
        'background-image': `url(${props.image_url})`
      }"
    ></div>
    <div class="item-title">{{ props.title }}</div>
    <div class="item-subtitle">
      {{ props.text }}
    </div>
  </div>
</template>

<script >
import { defineComponent, reactive } from "vue";

export default defineComponent({
  props: [
    'image_url',
    'title',
    'text',
  ],
  setup(props) {
    const viewState = reactive({
    });
    return {
      props,
      viewState,
    };
  },
});
</script>

<style lang="scss" scoped>
.popular-learning-item {
  height: 100%;
  background-color: $grey;
  .item-top-img {
    background-size: 100%;
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    @include mq(sm) {
      height: 235px;
    }
  }
  .item-title {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 0.1em;
    @include mq(sm) {
      margin-top: 30px;
      margin-left: 20px;
      font-size: 16px;
      line-height: 1.87;
    }
  }
  .item-subtitle {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.1em;
    white-space: pre-line;
  }
}
</style>