<template>
  <div class="popular-learning">
    <div class="title">
      <CommonBlockTitle title="選ばれる5つの理由"></CommonBlockTitle>
    </div>
    <div class="swiper-button-prev">
      <icon
        name="swiper-button-prev-btn"
        class="swiper-button-prev-btn-icon"
      ></icon>
    </div>
    <div class="swiper-button-next">
      <icon
        name="swiper-button-next-btn"
        class="swiper-button-next-btn-icon"
      ></icon>
    </div>
    <div class="swiper-index">
      <div class="swiper-index-page-text">Page&nbsp;</div>
      {{ viewState.realIndex }}/{{ viewState.lists.length }}
    </div>
    <swiper
      class="swiper-container"
      :loop="viewState.lists > 1"
      :slidesPerView="3"
      :slidesPerGroup="3"
      :breakpoints="breakpoints"
      :navigation="{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      }"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
      }"
      @activeIndexChange="activeIndexChange"
    >
      <swiper-slide
        v-for="(x, index) in viewState.lists"
        :key="index"
        :class="'popular-learning-items'"
      >
        <popularLearningItem
          :image_url="x.image_url"
          :title="x.title"
          :text="x.text"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import popularLearningItem from "./PopularLearningItem.vue";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
import { defineComponent, reactive } from "vue";
import CommonBlockTitle from "../../../components/CommonTitle/CommonBlockTitle.vue";
SwiperCore.use([Navigation, Autoplay]);
export default defineComponent({
  components: {
    popularLearningItem,
    Swiper,
    SwiperSlide,
    CommonBlockTitle,
  },
  setup() {
    const viewState = reactive({
      lists: [
        {
          image_url: require("../../../assets/img/reasun1.png"),
          title: "いつでも、どこでも、何度でも",
          text: `オンライン上で、いつでも、どこでも、学びたい講座を繰り返し学ぶことができます。`,
        },
        {
          image_url: require("../../../assets/img/reasun2.png"),
          title: "コース設計があるから体系的に学べる",
          text: `ラーニングパスとメモ機能でスキマ時間を活用しながら効率的に学習できます。
          講座は細かく分割されており約3分から視聴可能。`,
        },
        {
          image_url: require("../../../assets/img/reasun3.png"),
          title: "知識だけではない「実践」的なノウハウとスキル",
          text: `DX学習メソッドを随所に取り入れたコースと講座設計で、
          DX基礎から実践まで体系的に学べます。`,
        },
        {
          image_url: require("../../../assets/img/reasun4.png"),
          title: "30名を超える一流講師陣",
          text: `多くの一流講師陣の知識やノウハウすべてがあなたの学びのデータベースになります。`,
        },
        {
          image_url: require("../../../assets/img/reasun5.png"),
          title: "好きなテーマをピンポイントで",
          text: `気になるテーマや学びたい課題をピンポイントで検索。「いま」必要な講座を学べます
          講座視聴途中の理解度確認テストなどの仕掛けが記憶の定着を助けます。`,
        },
      ],
      popularCourse: {},
      realIndex: 1,
      isDisplayed: true,
    });

    const breakpoints = {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      375: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      600: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 20,
      },

      1000: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 28,
      },
      1366: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 28,
      },
    };

    // method
    const activeIndexChange = ({ realIndex }) => {
      viewState.realIndex = realIndex + 1;
    };

    return {
      viewState,
      breakpoints,

      // method
      activeIndexChange,
    };
  },
});
</script>
<style lang="scss" scoped>
.popular-learning {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  position: relative;
  padding: 65px;
  @include mq(sm) {
    padding: 60px 30px;
  }
  .title {
    padding-left: 50px;
    @include mq(sm) {
      padding-left: 20px;
    }
  }
  .swiper-button-prev {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    right: 125px;
    top: 135px;
    .swiper-button-prev-btn-icon {
      width: 40px;
      height: 40px;
    }
    @include mq(sm) {
      position: relative;
      top: 30px;
      left: 50px;
      right: unset;
      .swiper-button-prev-btn-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .swiper-button-next {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    right: 65px;
    top: 135px;
    .swiper-button-next-btn-icon {
      width: 40px;
      height: 40px;
    }
    @include mq(sm) {
      position: relative;
      top: 0;
      left: 90px;
      right: unset;
      .swiper-button-next-btn-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .swiper-index {
    display: none;
    @include mq(sm) {
      display: flex;
      height: 15px;
      align-items: baseline;
      position: relative;
      top: -24px;
      width: 100%;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.08em;
      color: $black-50;
      justify-content: flex-end;
      padding-right: 30px;
    }
  }
  .swiper-container {
    margin-top: 60px;
    margin-left: 50px;
    @include mq(sm) {
      margin-top: 30px;
      margin-left: 20px;
    }
  }
}
</style>
